import React, { useEffect, useState } from 'react';
import { Marker, MarkerLayer } from 'react-leaflet-marker';
import { TourPin } from '@app/shared/components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { atom_tourState } from '@app/shared/state/tour.atom';
import { useLeafletContext } from '@react-leaflet/core';
import { Pane, Polyline } from 'react-leaflet';
import { useGlobalHooks } from '@app/core/hooks/global-hooks';
import { getMapRoute } from '@app/shared/services/route.service';
import { atom_activeStopState } from '@app/shared/state/active-stop.atom';
import { Stop } from '@app/shared/models/tour.type';
import { atom_showModalContent } from '@app/shared/state/show-content-modal.atom';
import { atom_visitedLocations } from '@app/shared/state/visited.atom';
import { atom_filterState, atom_selectedFilterState } from '@app/shared/state/filter.atom';

export const TourLayer = React.memo(function TourLayer() {
	const [state_activeStopState, setState_activeStopState] =
		useRecoilState(atom_activeStopState);
	const [_, setState_showModal] = useRecoilState(atom_showModalContent);
	const state_TourState = useRecoilValue(atom_tourState);
	const [state_visitedLocations] = useRecoilState(
		atom_visitedLocations
	);
	const [selectedFilter] = useRecoilState(atom_selectedFilterState);

	const getPathColor = () => {
		return (
			state_TourState?.config?.['pathColor'] || _g.theme.palette.primary.main
		);
	};

	const [walkingWaypoints, setWalkingWaypoints] = useState([] as [number, number][]);

	const leaflet = useLeafletContext();
	const _g = useGlobalHooks();

	const handleClickOnPin = (stopId: string) => {
		const clickedStop = state_TourState?.stops.filter(
			(stop) => stop.id === stopId
		)[0];
		setTimeout(() => {
			if (
				clickedStop?.type === 'business' ||
				clickedStop?.type === 'bigStop' ||
				clickedStop?.type === 'smallStop' ||
				clickedStop?.type === 'WCStop' ||
				clickedStop?.type === 'waterStop' ||
				clickedStop?.type === 'noEntrance' ||
				clickedStop?.type === 'restaurant' ||
				clickedStop?.type === 'coffee' ||
				clickedStop?.type === 'shoppingCenter' ||
				clickedStop?.type === 'hospital' ||
				clickedStop?.type === 'streetFood' ||
				clickedStop?.type === 'localBusiness'
			) {
				setState_activeStopState(clickedStop);
				setState_showModal(true);
			}
		}, 200);
	};

	useEffect(() => {
		const firstStop = state_TourState?.stops[0];
		if (firstStop?.stop_location)
			leaflet.map.panTo(
				[firstStop?.stop_location.latitude, firstStop?.stop_location.longitude],
				{
					duration: 1,
					animate: true,
				}
			);

		(async () => {
			if (selectedFilter) {
				return setWalkingWaypoints([])
			}


			const tourWaypoints_latLngReversed: {
				type: any
				, langAndLang: [number, number]
			}[] =
				state_TourState?.stops

					.filter((stop) => {
						return stop.include_path !== false;
					})

					?.sort((a, b) => a?.order - b?.order)
					.map((stop) => ({
						type: stop.direction_type, langAndLang: [stop.stop_location.longitude,
						stop.stop_location.latitude,]
					})) || [];

			for (let i = 0; i < tourWaypoints_latLngReversed.length; i++) {
				if (tourWaypoints_latLngReversed[i].type === 'car') {
					tourWaypoints_latLngReversed[i].type = 'driving-car';
				} else if (tourWaypoints_latLngReversed[i].type === 'foot') {
					tourWaypoints_latLngReversed[i].type = 'foot-walking';
				} else if (tourWaypoints_latLngReversed[i].type === 'bike') {
					tourWaypoints_latLngReversed[i].type = 'cycling-regular';
				} else {
					tourWaypoints_latLngReversed[i].type = 'foot-walking';
				}
			}

			if (!tourWaypoints_latLngReversed?.length) return;



			let groups = [] as { type: string, waypoints: { type: string, langAndLang: [number, number] }[] }[];
			let lastType = null as string | null;

			tourWaypoints_latLngReversed.forEach(waypoint => {
				if (waypoint.type !== lastType) {
					// Start a new group
					groups.push({ type: waypoint.type, waypoints: [waypoint] });
					lastType = waypoint.type;
				} else {
					// Add to the last group
					groups[groups.length - 1].waypoints.push(waypoint);
				}
			});

			const all = await Promise.all(groups.map(group => getMapRoute(group.waypoints.map(w => w.langAndLang), group.type)))
			const routes = [];
			for (let i = 0; i < all.length; i++) {
				if (all[i]) {
					routes.push(...(all[i].features[0].geometry.coordinates as [number, number][]));
				}
			}
			setWalkingWaypoints(
				routes
			);
		})();
	}, [state_TourState, selectedFilter]);

	useEffect(() => {
		setTimeout(() => {
			const pins = document.querySelectorAll('.tour-pin');
			pins.forEach((pin) => {
				const stopId = pin.classList[1].split('-')[2];
				pin.addEventListener(
					'click',
					() => {
						handleClickOnPin(stopId);
					},
					false
				);
			});
		}, 100);
	}, [state_TourState, state_activeStopState]);

	const getPinDrawer = (stop: Stop) => {
		if (stop.icon) {
			return <i className={`fa-solid fa-${stop.icon} scale-100`}></i>;
		}

		switch (stop.type) {
			case 'bigStop': {
				return !isNaN(stop.stop_index) ? stop.stop_index.toString() : '';
			}
			case 'smallStop': {
				return <i className="fa-solid fa-lightbulb scale-100"></i>;
			}
			case 'business': {
				return <i className="fa-solid fa-shop scale-100" />;
			}
			case 'starStop': {
				return <i className="fa-solid fa-star scale-100" />;
			}
			case 'WCStop': {
				return <i className="fa-solid fa-restroom-simple scale-100" />;
			}
			case 'waterStop': {
				return <i className="fa-solid fa-faucet-drip scale-100" />;
			}
			case 'noEntrance': {
				return <i className="fa-solid fa-ban scale-100" />;
			}
			case 'restaurant': {
				return <i className="fa-solid fa-utensils scale-100" />;
			}
			case 'coffee': {
				return <i className="fa-solid fa-mug-hot scale-100" />;
			}
			case 'shoppingCenter': {
				return <i className="fa-solid fa-cart-shopping scale-100" />;
			}
			case 'hospital': {
				return <i className="fa-solid fa-bed scale-100" />;
			}
			case 'streetFood': {
				return <i className="fa-solid fa-smile scale-100" />;
			}
			case 'localBusiness': {
				return <i className="fa-solid fa-shop scale-100" />;
			}
			default: {
				return '';
			}
		}
	};
	return (
		<div className="fragment">
			{state_TourState && (
				<Pane name="tour-stops" style={{ zIndex: 250 }}>
					<MarkerLayer>
						{state_TourState?.stops
							.filter(
								(stop) =>
									stop.id !== state_activeStopState?.id &&
									stop?.display_icon !== false
							)
							.map((stop) => {
								return (
									<Marker
										key={'thestop' + stop.id}
										position={{
											lat: stop.stop_location.latitude,
											lng: stop.stop_location.longitude,
										}}
										placement="bottom"
										size={[0, 0]}
									>
										<TourPin
											visited={state_visitedLocations?.[stop?.id]}
											size={30}
											id={stop.id}
										>
											{getPinDrawer(stop)}
										</TourPin>
									</Marker>
								);
							})}
					</MarkerLayer>
				</Pane>
			)}

			<Pane name="tour-route" style={{ zIndex: 210 }}>
				<Polyline
					pathOptions={{
						color: getPathColor(),
						dashArray: '10, 10',
					}}
					positions={walkingWaypoints.map((w) => [w[1], w[0]])}
				/>
			</Pane>
			{state_activeStopState && (
				<Pane name="tour-active-stop" style={{ zIndex: 260 }}>
					<MarkerLayer>
						<Marker
							key={'rendered_active_stop' + state_activeStopState.id}
							position={{
								lat: state_activeStopState.stop_location.latitude,
								lng: state_activeStopState.stop_location.longitude,
							}}
							placement="bottom"
							size={[0, 0]}
						>
							<TourPin size={30} active={true} id={state_activeStopState.id}>
								{getPinDrawer(state_activeStopState)}
							</TourPin>
						</Marker>
					</MarkerLayer>
				</Pane>
			)}
		</div>
	);
});
