import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { LatLngExpression } from 'leaflet';
import { MapContainer } from 'react-leaflet';
import { OfflineTileLayer } from '@app/features/map/offline-tile-layer/OfflineTileLayer';
import { TourLayer } from '@app/features/map/tour-layer/TourLayer';
import 'leaflet/dist/leaflet.css';
import { TourOverlayLayer } from '@app/features/map/tour-overlay-layer/TourOverlayLayer';
import { TourSummaryOverlay } from '@app/features/map/tour-summary-overlay/TourSummaryOverlay';
import { DeviceLocationLayer } from '@app/features/map/device-location-layer/DeviceLocationLayer';
import Cube from '@app/shared/components/cube/Cube';
import { useRecoilState } from 'recoil';
import {  atom_hideState, atom_startTourState } from '@app/shared/state/loading.atom';
import useGeolocation from 'react-hook-geolocation';
import { atom_tourState } from '@app/shared/state/tour.atom';

export const MapPage = React.memo(function MapPage() {
	const position: LatLngExpression = [31.788009038677018, 34.62986136174621];
	const [startTour] = useRecoilState(atom_startTourState);
	const [hidePage] = useRecoilState(atom_hideState);
	const [state_TourState] = useRecoilState(atom_tourState);
	
	const geolocation = useGeolocation({
		enableHighAccuracy: true,
		maximumAge: 15000,
		timeout: 1000,
	});

	return (
		<Box className="h-full w-full">
			{state_TourState?.config?.['active_filter'] && hidePage && !startTour && <Cube />}

			<MapContainer
				className="h-full w-full"
				center={position}
				zoom={13}
				zoomControl={false}
			>
				<OfflineTileLayer />
				<TourLayer />
				<TourOverlayLayer />
				{geolocation.longitude && !geolocation.error && <DeviceLocationLayer />}
			</MapContainer>

			<TourSummaryOverlay />
		</Box>
	);
});
