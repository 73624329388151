import { atom } from 'recoil';

export const atom_filterState = atom<string[]>({
	key: 'Filter',
	default: [],
});

export const atom_selectedFilterState = atom<string>({
	key: 'SelectedFilter',
	default: '',
});