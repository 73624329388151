import { atom } from 'recoil';

export const atom_loadingState = atom<boolean>({
	key: 'loading',
	default: false,
});

export const atom_startTourState = atom<boolean>({
	key: 'startTour',
	default: false,
});

export const atom_hideState = atom<boolean>({
	key: 'hide',
	default: false,
});