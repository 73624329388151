import { Seperator } from '@app/shared/components/Seperator';
import { Stop } from '@app/shared/models/tour.type';
import { atom_activeStopState } from '@app/shared/state/active-stop.atom';
import { atom_openedStopState } from '@app/shared/state/opened-stop.atom';
import { atom_showModalContent } from '@app/shared/state/show-content-modal.atom';
import { atom_tourState } from '@app/shared/state/tour.atom';
import { atom_visitedLocations } from '@app/shared/state/visited.atom';
import { Box, Button, Container, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useRecoilState, useRecoilValue } from 'recoil';
import { RateUsModal } from './RateUsModal';
import { TriviaPage } from './TriviaPage';

type StopDetailsProps = {
	children?: React.ReactNode;
};

export const StopDetails: React.FC = React.memo<StopDetailsProps>(
	function StopDetails(_p) {
		const { t } = useTranslation();
		const [state_activeStopState] = useRecoilState(atom_activeStopState);
		const tourState = useRecoilValue(atom_tourState);
		const [, setState_openedStopState] = useRecoilState(atom_openedStopState);
		const [state_showModal, setState_showModal] = useRecoilState(
			atom_showModalContent
		);
		const [state_visitedLocations, setState_visitedLocations] = useRecoilState(
			atom_visitedLocations
		);
		const [showRatingModal, setShowRatingModal] = useState(false);

		function getHighestStopIndex() {
			const highest = tourState?.stops?.reduce((acc: number, next: Stop) => {
				if (next.stop_index && next.stop_index > acc) {
					return next.stop_index;
				}
				return acc;
			}, -1);
			return highest;
		}

		return (
			<Box className="flex flex-col gap-10">
				{state_activeStopState?.all_media.map((media, i) => {
					if (media.type?.includes('video'))
						return (
							<div className="fragment">
								<Box key={'media' + media.order}>
									<Typography className="p-4 pb-2">{media.title}</Typography>
									<ReactPlayer
										// onError={(err) => alert(JSON.stringify(err))}
										key={'rplayer' + media.order}
										style={{ maxHeight: media?.isVertical ? 'unset' : '30vh' }}
										className={
											media?.isVertical ? 'vertical-config' : 'fragment'
										}
										width="100%"
										url={media.url?.split('&token=')?.[0]}
										controls
									/>
								</Box>
								{i < state_activeStopState?.all_media?.length - 1 && (
									<Seperator />
								)}
							</div>
						);

					if (media.type?.includes('audio'))
						return (
							<div className="fragment">
								<Box key={'raudio' + media.order}>
									<Typography className="p-4 pb-2">{media.title}</Typography>
									<audio
										controls
										className="h-7 mt-1 mb-2"
										style={{ width: '100%' }}
									>
										<source
											src={media.url?.split('&token=')?.[0]}
											type="audio/mpeg"
										/>
										Your browser does not support the audio element.
									</audio>
								</Box>
								{i < state_activeStopState?.all_media?.length - 1 && (
									<Seperator />
								)}
							</div>
						);

					if (media.type?.includes('question'))
						return (
							<div className="fragment">
								<TriviaPage question={media} />
								{i < state_activeStopState?.all_media?.length - 1 && (
									<Seperator />
								)}
							</div>
						);

					if (media.type?.includes('image'))
						return (
							<Container>
								<Typography className="p-4 pb-2">{media.title}</Typography>

								<img width="100%" src={media?.url} />
							</Container>
						);

					if (media.type?.includes('text'))
						return (
							<div className="fragment">
								<Box className="p-4 pb-2" key={'rtext'  + media.order}>
									<Typography>{media.title}</Typography>
									<Typography
										style={{ wordBreak: 'break-word' }}
										variant="body2"
									>
										{media.content}
									</Typography>
								</Box>
								{i < state_activeStopState?.all_media?.length - 1 && (
									<Seperator />
								)}
							</div>
						);
					if (media.type?.includes('html'))
						return (
							<Box className="p-4 pb-2 ml-2" key={'rhtml' + media.order}>
								<div dangerouslySetInnerHTML={{ __html: media?.content }}></div>
							</Box>
						);
				})}
				<div style={{ padding: '5%' }}></div>
				<RateUsModal
					showModal={showRatingModal}
					onClose={() => {
						setShowRatingModal(false);
						setState_openedStopState(undefined);
						setState_showModal(false);
					}}
				/>
				<Box className="flex justify-center">
					<Button
						style={{ width: '80%' }}
						className="mb-5"
						onClick={() => {
							const storage = JSON.parse(localStorage.getItem('visitedStops') || '{}') as any
							const visitedStops = new Set<string>(Object.values(storage).length > 0 ? Object.values(storage) : undefined);
							if (!visitedStops.has((state_activeStopState as any)?.id)) {
								visitedStops.add((state_activeStopState as any)?.id);
								localStorage.setItem('visitedStops', JSON.stringify(({ ...Array.from(visitedStops) })));
							}
							if (state_activeStopState?.stop_index === getHighestStopIndex()) {
								setShowRatingModal(true);
							} else {
								setState_openedStopState(undefined);
								setState_showModal(false);
							}

							setState_visitedLocations({
								...state_visitedLocations,
								...(state_activeStopState?.id && {
									[state_activeStopState?.id]: true
								})
							});
						}}
					>
						{t('on_tour.stop.i_finished_the_stop')}
					</Button>
				</Box>
			</Box>
		);
	}
);
