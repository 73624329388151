// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import openrouteservice from 'openrouteservice-js';


export const getMapRoute = async (waypoints: [number, number][], profile = 'foot-walking') => {
	const Directions = new openrouteservice.Directions({
		api_key: '5b3ce3597851110001cf6248ad8483d00dbb489e958c465e60ca3baa',
	});
	try {
		return await Directions.calculate({
			coordinates: waypoints,
			profile,
			format: 'geojson',
		});
	} catch (err) {
		console.log({ err });
		return null;
	}
}
