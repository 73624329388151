import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { useGlobalHooks } from '@app/core/hooks/global-hooks';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { atom_tourState } from '@app/shared/state/tour.atom';

export type TourPinProps = {
  id?: string;
  size: number;
  active?: boolean;
  children?: React.ReactNode;
  visited?: boolean;
};

export const TourPin = React.memo(
	React.forwardRef<BoxProps, TourPinProps & BoxProps>(function TourPin(
		_p,
		ref
	) {
		const _g = useGlobalHooks();
		const activeTour = useRecoilValue(atom_tourState);

		const { i18n } = useTranslation();
		const getPinColor = () => {
			return activeTour?.config?.pinColor || _g.theme.palette.primary.main;
		};
		return (
			<Box
				ref={ref}
				className={`tour-pin stop-id-${
					_p.id
				} inline-block relative origin-bottom ${
					(_p.active || _p.visited) && 'scale-100'
				}`}
				sx={{
					position: 'relative',
					top: _p.size * -1.5,
					left: (i18n as any).language === 'en' ? 'unset' : _p.size * -0.5,
					zIndex: '999999 !important',
				}}
			>
				<svg
					className="absolute top-0 scale-100"
					style={{ transformOrigin: '50% 35%' }}
					width={_p.size}
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1913.42 2705.38"
				>
					<defs>
						<style>
              .cls-2{`{fill:${_g.theme.palette.secondary.contrastText};}`}
						</style>
					</defs>
					<title>locaiton-pin</title>
					<g id="Layer_2" data-name="Layer 2">
						<g id="Layer_2-2" data-name="Layer 2">
							<path
								className="cls-2"
								d="M1913.42,956.71c0,429.18-631.21,1366.08-868.21,1702.73-43.12,61.25-133.88,61.25-177,0C631.21,2322.79,0,1385.89,0,956.71,0,428.33,428.33,0,956.71,0S1913.42,428.33,1913.42,956.71Z"
							/>
						</g>
					</g>
				</svg>
				<svg
					className="relative scale-100"
					width={_p.size}
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 1913.42 2705.38"
				>
					<defs>
						<style>.cls-1{`{fill:${getPinColor()};}`}</style>
					</defs>
					<title>locaiton-pin</title>
					<g id="Layer_2" data-name="Layer 2">
						<g id="Layer_2-2" data-name="Layer 2">
							<path
								className="cls-1"
								d="M1913.42,956.71c0,429.18-631.21,1366.08-868.21,1702.73-43.12,61.25-133.88,61.25-177,0C631.21,2322.79,0,1385.89,0,956.71,0,428.33,428.33,0,956.71,0S1913.42,428.33,1913.42,956.71Z"
							/>
						</g>
					</g>
				</svg>
				{!(_p.active || _p.visited) && (
					<svg
						className="absolute top-0 left-0 scale-75"
						style={{ transformOrigin: '50% 35%' }}
						width={_p.size}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 1913.42 2705.38"
					>
						<defs>
							<style>
                .cls-3{`{fill:${_g.theme.palette.secondary.contrastText};}`}
							</style>
						</defs>
						<title>locaiton-pin</title>
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_2-2" data-name="Layer 2">
								<path
									className="cls-3"
									d="M1913.42,956.71c0,429.18-631.21,1366.08-868.21,1702.73-43.12,61.25-133.88,61.25-177,0C631.21,2322.79,0,1385.89,0,956.71,0,428.33,428.33,0,956.71,0S1913.42,428.33,1913.42,956.71Z"
								/>
							</g>
						</g>
					</svg>
				)}
				<Box
					className="flex items-center justify-center"
					sx={{
						width: _p.size,
						height: _p.size,
						position: 'absolute',
						top: 0,
						color:
              _p.active || _p.visited
              	? _g.theme.palette.primary.contrastText
              	: '#3B3A36',
						fontSize: typeof _p.children === 'string' ? '1.3em' : '1em',
						fontWeight: _p.active || _p.visited ? 800 : 600,
						fontFamily: _g.theme.typography.fontFamily,
					}}
				>
					<Box sx={{ zIndex: 200 }}>{_p.children}</Box>
				</Box>
			</Box>
		);
	})
);
