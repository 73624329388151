import React from 'react';

import { createTheme, Shadows, ThemeProvider } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { atom_tourState } from '@app/shared/state/tour.atom';

type ThemeProviderProps = {
  children?: React.ReactNode;
};

export const brandTheme = (config?: Record<string, string>) =>
	createTheme({
		shadows: Array(25).fill('none') as Shadows,
		palette: {
			primary: {
				main: config?.['mainColor'] || '#E14A1B',
			},
			secondary: {
				main: '#121A3E',
			},
		},
		typography: {
			allVariants: {
				fontFamily: 'rubik',
				color: '#3C3A36',
			},
			h1: {
				fontSize: 35,
				fontWeight: 400,
			},
			h2: {
				fontSize: 20,
				fontWeight: 500,
			},
			caption: {
				fontSize: 12,
				fontWeight: 500,
				color: '#858585',
			},
			body1: {
				fontSize: 15,
				fontWeight: 500,
			},
			body2: {
				wordBreak: 'break-all',
				fontSize: 14,
				fontWeight: 400,
			},
			button: {
				fontSize: 16,
				fontWeight: 500,
				textTransform: 'none',
				lineHeight: '20x',
			},
		},
		components: {
			MuiButton: {
				defaultProps: {
					variant: 'contained',
				},
				styleOverrides: {
					root: () => ({
						borderRadius: 99999,
						padding: '12px 32px',
					}),
				},
			},
			MuiLink: {
				styleOverrides: {
					root: () => ({
						fontSize: 12,
						fontWeight: 500,
					}),
				},
			},
		},
	});

export const AppThemeProvider: React.FC<ThemeProviderProps> = (_p) => {
	const state_TourState = useRecoilValue(atom_tourState);
	return (
		<ThemeProvider theme={brandTheme(state_TourState?.config)}>
			{_p.children}
		</ThemeProvider>
	);
};
