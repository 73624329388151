import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { NavigationOptions } from './NavigationOptions';

export type StopCardProps = {
  title: string;
  categories: React.ReactNode[];
  image_src: string;
  children?: React.ReactNode;
  stop?: any;
};

export const StopCard = React.memo(
	React.forwardRef<BoxProps, StopCardProps & BoxProps>(function StopCard(
		_p,
		ref
	) {
		return (
			<Box
				ref={ref}
				className="flex bg-white rounded-md overflow-hidden p-0.5"
				{..._p}
			>
				<Box className="p-3 flex flex-1 flex-col justify-between">
					<Typography variant="button">{_p.title}</Typography>
					<Box className="flex flex-wrap justify-start">
						{_p.categories?.map((category) => (
							<Typography
								key={'cat' + category?.toString()}
								variant="body2"
								textTransform="capitalize"
							>
								{category}&nbsp;&nbsp;
							</Typography>
						))}
					</Box>
				</Box>
				<Box style={{ position: 'relative' }} className="flex">
					<div style={{ height: 60, marginLeft: 10, marginTop: 5 }}>
						<NavigationOptions stop={_p.stop} omitWaze={true} />
					</div>
					{/* <Box className=" py-0.5 px-1 flex flex-col justify-end">
            <img
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `https://www.google.com/maps/@${_p.stop.stop_location.latitude},${_p.stop.stop_location.longitude},17z`
                );
              }}
              src="/images/google-maps-icon.png"
            />
          </Box> */}
					<img
						src={_p.image_src}
						width="110px"
						className="object-cover object-center"
						style={{
							minWidth: '110px',
							borderStartEndRadius: 5,
							borderEndEndRadius: 5,
						}}
					/>
				</Box>
			</Box>
		);
	})
);
