import * as React from "react";
import LinearProgress, {
  LinearProgressProps
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useRecoilValue } from "recoil";
import { atom_downloadProgressBar } from "@app/shared/state/show-content-modal.atom";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number; isFinished: boolean }
) {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box sx={{ width: "80%", mr: 1 }}>
        <LinearProgress sx={{ height: 15 }} variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export function LinearWithValueLabel({ isFinished }: { isFinished: boolean }) {
  const [progress, setProgress] = React.useState(0);
  const newProg = useRecoilValue(atom_downloadProgressBar);

  React.useEffect(() => {
    const timer = setInterval(() => {
      !isFinished
        ? setProgress(prevProgress =>
            prevProgress >= 99
              ? 99
              : prevProgress >= 90
              ? prevProgress + 9
              : prevProgress + 10
          )
        : setProgress(100);
    }, 2500);
    return () => {
      clearInterval(timer);
    };
  }, [isFinished]);

  return (
    <Box>
      <LinearProgressWithLabel isFinished={isFinished} value={newProg} />
    </Box>
  );
}
