import React, { useState } from 'react';
import { Box, BoxProps, Button, Typography } from '@mui/material';
import { Stop } from '@app/shared/models/tour.type';
import { Seperator } from '@app/shared/components';
import ReactPlayer from 'react-player';
import backgroundImage from 'src/assets/images/bg-question.svg';
import { useTranslation } from 'react-i18next';

export type TriviaPageProps = {
  question: any;
  children?: React.ReactNode;
};

export const TriviaPage = React.memo(
	React.forwardRef<BoxProps, TriviaPageProps & BoxProps>(function TriviaPage(
		{ question },
		ref
	) {
		const [correctAnswer, setCorrectAnswer] = useState(-1);
		const { t } = useTranslation();

		const handleSelect = () => {
			question.options.map((option: any, idx: number) => {
				if (option.isAnswer) {
					setCorrectAnswer(idx);
				}
			});
		};

		const correctAnswerStyle = (idx: number) => {
			if (correctAnswer === idx) {
				return 'rgb(41, 230, 166)';
			}
			return '#FF725F';
		};

		return (
			<Box ref={ref}>
				<Box
					style={
            {
            	color: 'white',
            	backgroundImage: `url(${backgroundImage})`,
            	'background-size': 'cover',
            	height: 670,
            	paddingTop: -40,
            } as any
					}
				>
					<Box className="flex justify-center pt-7 mb-1">
						<Typography
							color="white"
							style={{ fontSize: 24, fontWeight: 600 }}
							className="center mt-6"
							variant="h1"
						>
							{t('on_tour.stop.who_wants_to_play_trivia')}
						</Typography>
					</Box>
					<Box className="flex justify-center">
						<Typography
							style={{ textAlign: 'center' }}
							color="white"
							className="center mb-4 mt-2"
							variant="h2"
						>
							{question.question_text}
						</Typography>
					</Box>
					{question?.options?.map((option: any, idx: number) => (
						<Box
							key={'q' + idx}
							className="pt-7 pb-7"
							onClick={handleSelect}
							style={
                {
                	borderRadius: 10,
                	margin: 10,
                	// color: "white",
                	background:
                    correctAnswer !== -1 ? correctAnswerStyle(idx) : 'white',
                	// background: idx % 2 === 0 ? "#FF725F" : "rgb(41, 230, 166)",
                	outline: correctAnswer === idx && '5px solid white',
                } as any
							}
						>
							<Box className="flex justify-center">
								<Typography
									color={true ? 'black' : 'white'}
									className="center"
									variant="h2"
								>
                  - {idx + 1} -
								</Typography>
							</Box>
							<Box className="mr-2 flex justify-center">
								<Typography
									color={true ? 'black' : 'white'}
									className="center"
									variant="h2"
								>
									{option.option}
								</Typography>
							</Box>
						</Box>
					))}
				</Box>
			</Box>
		);
	})
);
