import React from 'react';
import { Box, BoxProps, Button, Typography } from '@mui/material';
import { Stop } from '@app/shared/models/tour.type';
import { Seperator } from '@app/shared/components';
import ReactPlayer from 'react-player';
import { NavigationOptions } from '@app/shared/components/NavigationOptions';
import { useTranslation } from 'react-i18next';
import { StopDetails } from 'src/features/map/stop-details/StopDetails';

export type BigStopPageProps = {
  stop: Stop;
  onClickBack?: () => void;
  children?: React.ReactNode;
};

export const BigStopPage = React.memo(
	React.forwardRef<BoxProps, BigStopPageProps & BoxProps>(function BigStopPage(
		_p,
		ref
	) {
		const { i18n } = useTranslation();
		return (
			<Box ref={ref}>
				<Box className="flex justify-between p-4">
					<Button onClick={_p.onClickBack} className="p-0 w-10 h-10 min-w-min">
						<i
							className={`fa-solid fa-chevron-${
								i18n.language === 'he' ? 'right' : 'left'
							}`}
						/>
					</Button>
					<Box className="flex flex-wrap justify-start items-center">
						{_p.stop.tags?.map((tag) => (
							<Typography
								key={'tag' + tag?.toString()}
								variant="body2"
								textTransform="capitalize"
							>
								{tag}&nbsp;&nbsp;
							</Typography>
						))}
					</Box>
					<Box>
						<NavigationOptions stop={_p.stop} omitWaze={true} />
						{/* <Button variant="text" className="p-0 w-10 h-10 min-w-min">
            <img src="/images/google-maps-icon.png" width={20} />
          </Button> */}
					</Box>
				</Box>
				<Box className="px-4">
					<Typography variant="h1" className="mb-2">
						{_p.stop.s_title}
					</Typography>
					{/* <Typography variant="body1">תיאור</Typography> */}
					<Typography style={{ wordBreak: 'break-word' }} variant="body2">
						{_p.stop.s_smallDesc}
					</Typography>
					<Seperator />
				</Box>
				<StopDetails />
				<Box sx={{ minHeight: '20vh' }}></Box>
			</Box>
		);
	})
);
