import React, { useEffect } from 'react';
import { Box, BoxProps, Button, Typography } from '@mui/material';
import { Stop } from '@app/shared/models/tour.type';
import { NavigationOptions } from '@app/shared/components/NavigationOptions';
import { atom_tourState } from '@app/shared/state/tour.atom';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

export type OpeningStopBlockProps = {
  stop: Stop;
  onClickSkip?: () => void;
  children?: React.ReactNode;
};

export const OpeningStopBlock = React.memo(
	React.forwardRef<BoxProps, OpeningStopBlockProps & BoxProps>(
		function OpeningStopBlock(_p, ref) {
			const { t } = useTranslation('');
			const state_TourState = useRecoilValue(atom_tourState);

			useEffect(() => {
				setTimeout(() => {
					scrollTo(0, 0);
				}, 0);
			}, [stop]);

			return (
				<Box ref={ref} className="p-4 text-center" style={{ height: 1000 }}>
					{/* <Typography variant="h2" className="my-6">
            OUR STARTING POINT IS
          </Typography> */}
					<Typography variant="h1" className="m-4">
						{_p.stop.s_title}
					</Typography>
					<Typography variant="caption" className="inline-block mb-4">
						{_p.stop.s_smallDesc}
					</Typography>
					<Box className="flex justify-center">
						<img
							style={{ maxHeight: '35vh' }}
							src={_p?.stop?.loc_pics?.[0].url?.split('&token=')?.[0]}
							className="rounded-xl"
						/>
					</Box>

					<Typography variant="h2" className="my-6 px-12">
						{t('on_tour.before_start_stop.description')}
					</Typography>
					{state_TourState?.starting_point && (
						<NavigationOptions
							stop={{ location: state_TourState?.starting_point } as any}
						/>
					)}
					<Button onClick={_p.onClickSkip} variant="text" fullWidth>
						{t('on_tour.before_start_stop.skip')}
					</Button>
				</Box>
			);
		}
	)
);
