import { atom_tourState } from "@app/shared/state/tour.atom";
import { useTheme } from "@mui/material";
import { useRecoilState } from "recoil";

export function useGlobalHooks() {
  const theme = useTheme();
  return {
    theme,
  };
}
