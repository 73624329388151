import { atom } from 'recoil';
import { Tour } from '@app/shared/models/tour.type';

export const atom_tourState = atom<Tour | undefined>({
	key: 'Tour',
	default: undefined,
});

export const atom_originalTourState = atom<Tour | undefined>({
	key: 'Original_Tour',
	default: undefined,
});
