import React from 'react';
// import { I18nProvider } from '@app/core/localization/I18nProvider';
import { StateProvider } from '@app/core/state/StateProvider';
import { AppThemeProvider } from '@app/core/theme/ThemeProvider';

type GlobalProvidersProps = {
  children?: React.ReactNode;
};

export const GlobalProviders: React.FC<GlobalProvidersProps> = (_p) => {
	return (
		<StateProvider>
			<AppThemeProvider>
				{/* <I18nProvider>{_p.children}</I18nProvider> */}
				{_p.children}
			</AppThemeProvider>
		</StateProvider>
	);
};
