import { atom_tourState } from "@app/shared/state/tour.atom";
import {
  Box,
  Button,
  Input,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-responsive-modal";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import rateUsImage from "../../../assets/images/rate-us.png";
export function RateUsModal({
  showModal,
  onClose,
}: {
  showModal: boolean;
  onClose: any;
}) {
  const [rating, setRating] = useState(5);
  const [text, setText] = useState("");
  const { search } = useLocation();
  const state_TourState = useRecoilValue(atom_tourState);
  const { t } = useTranslation();
  async function handleSendRating() {
    await fetch(
      `https://europe-west3-tours-app-1579553856346.cloudfunctions.net/newReviewWebVersion/?tID=${
        state_TourState?.id
      }&uID=${search?.split("uId=")?.[1]}&rate=${rating}&text=${text}`,
      { method: "post" }
    ).then((res) => res.json());
    onClose();
  }
  return (
    <Modal
      styles={{ root: { zIndex: 999999 } }}
      open={showModal}
      onClose={onClose}
      center
    >
      <Box
        style={{
          flexDirection: "column",
          alignItems: "center",
        }}
        className="flex justify-center align-center"
      >
        <img className="m-2" src={rateUsImage} />
        <Typography className="m-2" variant="h6">
          {t("on_tour.rate_us.title")}
        </Typography>
        <Typography
          style={{ textAlign: "center" }}
          className="m-2"
          variant="body1"
        >
          {t("on_tour.rate_us.description")}
        </Typography>
        <Rating
          size="large"
          className="m-2"
          name="read-only"
          onChange={(event, newValue) => {
            setRating(newValue as number);
          }}
          value={rating}
        />
        <TextField
          onChange={(e) => setText(e.target.value)}
          className="m-2"
          sx={{ width: "100%" }}
          id="outlined-multiline-static"
          multiline
          rows={4}
          placeholder="Cool App !"
        />
        <Button
          onClick={handleSendRating}
          className="m-2"
          sx={{ width: "100%" }}
        >
          {t("on_tour.rate_us.send")}
        </Button>
      </Box>
    </Modal>
  );
}
