import { atom } from "recoil";

export const atom_showModalContent = atom<boolean>({
  key: "ShowModal",
  default: false
});

export const atom_downloadProgressBar = atom<number>({
  key: "Progress",
  default: 0
});
