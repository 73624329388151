import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '@app/App';
import '@app/index.css';
import {version } from 'package.json'

// import * as Sentry from '@sentry/react';

if ('serviceWorker' in navigator) {
	window.addEventListener('load', function() {
		navigator.serviceWorker.register('/sw.js').then(function(registration) {
			// Registration was successful
			console.log('ServiceWorker registration successful with scope: ', registration.scope);
		}, function(err) {
			// registration failed :(
			console.log('ServiceWorker registration failed: ', err);
		});
	});
}

console.log({version})
// Sentry.init({
// 	dsn: 'https://4802d1c5829f416791eb7ff875203e55@o4505494863216640.ingest.us.sentry.io/4505494878158848',
// 	integrations: [
// 		new Sentry.BrowserTracing({
// 			// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
// 			tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
// 		}),
// 		new Sentry.Replay(),
// 	],
// 	// Performance Monitoring
// 	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
// 	// Session Replay
// 	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
