// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { atom } from 'recoil';
const fromStorage = JSON.parse(localStorage.getItem('visitedStops') || '{}');
export const atom_visitedLocations = atom<Record<string, boolean>>({
	key: 'Visited',
	default: Object.values(fromStorage).reduce((acc: Record<string, boolean>, curr: string) => {
		acc[curr] = true;
		return acc;
	}, {})
});
