// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBokNLNIf99phf_GniwbGzdxAxT8Y47Sgg",
  authDomain: "tours-app-1579553856346.firebaseapp.com",
  databaseURL: "https://tours-app-1579553856346.firebaseio.com",
  projectId: "tours-app-1579553856346",
  storageBucket: "tours-app-1579553856346.appspot.com",
  messagingSenderId: "613221148836",
  appId: "1:613221148836:web:c9cbad89095f79bdf69e31",
  measurementId: "G-4GH2JYJF29"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
